import React, { createRef } from "react"

import PersonGlobal from "@/person/PersonGlobal"
import PersonCompany from "@/person/PersonCompany"
import PersonIndividual from "@/person/PersonIndividual"
import PersonProfessional from "@/person/PersonProfessional"
import { runScriptLocally } from "@/_services/scripts"
import { personTypes } from "@/_services/personUtils"
import { getList } from "@/_services/lists"
import { debug } from "@/_services/utils"

class PersonComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      layout: undefined,
    }
    this.layoutScript = createRef()
  }

  componentDidMount() {
    const { person = {}, usePersonLayout = false, useProspectLayout = false } = this.props
    if (usePersonLayout || useProspectLayout) this.executeLayout()
    getList("currency", c => this.setState({ currencies: Object.keys(c.labels), person }))
  }

  componentDidUpdate(prevProps) {
    const { person = {}, usePersonLayout = false, useProspectLayout = false, detailed } = this.props
    const {
      person: prevPerson = {},
      usePersonLayout: prevUsePersonLayout = false,
      useProspectLayout: prevUseProspectLayout = false,
      detailed: prevDetailed,
    } = prevProps

    const useLayout = usePersonLayout || useProspectLayout
    const prevUseLayout = prevUsePersonLayout || prevUseProspectLayout
    if (useLayout && (person !== prevPerson || useLayout !== prevUseLayout || detailed !== prevDetailed)) this.executeLayout(prevPerson)
  }

  handleSetPersonAddressState = patch => {
    if (!patch) return
    const { person, handleSetPersonState } = this.props

    const copyAddresses = (person?.addresses || []).map(address => ({ ...address }))
    if (!copyAddresses.length) copyAddresses.push({})
    copyAddresses[0] = { ...copyAddresses[0], ...patch }
    patch = { addresses: copyAddresses }

    handleSetPersonState(patch)
  }

  executeLayout = async prevPerson => {
    const { layout } = this.state
    const cache = layout?.cache || {}
    const { person = {}, inModal, cardName, descendantRelation, entityName, entity, role, useProspectLayout, detailed } = this.props

    let scriptName = useProspectLayout ? "prospect-layout" : "person-layout"
    if (person?.type) scriptName += `-${person.type}`

    this.layoutScript.current = this.layoutScript.current || (await runScriptLocally({ scriptName, acceptMissing: true }))
    if (this.layoutScript.current?.execute) {
      const parameters = {
        person,
        parentEntityName: entityName,
        parentEntity: entity,
        inModal,
        cardName,
        descendantRelation,
        role,
        cache,
        prevPerson,
        detailed,
      }
      if (debug) console.log(scriptName, ": parameters=", parameters)
      const newLayout = await this.layoutScript.current.execute(parameters)
      if (newLayout) {
        newLayout.cache = cache
        this.setState({ layout: newLayout })
      }
    }
  }

  render() {
    const {
      isProspect,
      person = {},
      tabPerson,
      personIndex,
      detailed,
      readOnly,
      modelPath,
      tabPersonModelPath,
      showRegistrationConfirmModal,
      allowDuplicates,
      usePersonLayout,
      useProspectLayout,
      addressExtraInfo,
      addressLanguage,
      addressRegion,
      handleSetPersonState,
      handleSetTabPersonState,
      rows,
      registrationProps,
      defaultRows,
      showSepaMandates,
      fromProspectOrPersonPage,
      showEdit,
      showAddressOpenModalButton,
      showBankOpenModalButton,
      personRegistrationsModal,
    } = this.props
    const { layout, currencies } = this.state

    const personCard = ((usePersonLayout || useProspectLayout) && layout?.cards?.find(it => it.card === "Person")) || {}

    let _rows
    if (Array.isArray(rows) && rows.length) _rows = rows
    else if (Array.isArray(personCard.props?.rows) && personCard.props.rows.length) _rows = personCard.props.rows
    else if (typeof rows === "function") _rows = rows({ person, personIndex, detailed })
    else _rows = defaultRows

    const props = {
      isProspect,
      person,
      tabPerson,
      detailed,
      addressExtraInfo,
      addressLanguage,
      addressRegion,
      readOnly,
      layout,
      modelPath,
      tabPersonModelPath,
      showRegistrationConfirmModal,
      allowDuplicates,
      usePersonLayout,
      useProspectLayout,
      handleSetPersonState,
      handleSetTabPersonState,
      handleSetPersonAddressState: this.handleSetPersonAddressState,
      currencies,
      registrationProps,
      showSepaMandates,
      ...(personCard.props || {}),
      rows: _rows,
      fromProspectOrPersonPage,
      showEdit,
      showAddressOpenModalButton,
      showBankOpenModalButton,
      personRegistrationsModal,
    }

    // Read below before making any change to know the specific constraints to respect.
    //
    // 1.
    // The below components support a "detailed" props that allow toggling the view of additional fields.
    // It is important that the elements that are hidden when detailed is falsy remain present in the the DOM using the CSS display: none
    // even when not shown via the detailed props so that the mandatory state check can still target them.
    //
    // 2.
    // If you need to completely hide the base fields of these components (the name field of the type company for instance)
    // and create them in another place (in layout cards for instance),
    // then hide the base fields and create them with another field name (for instance altName) and map them together in the layout script.
    // Indeed hiding the base fields and creating them elsewhere with the same field name would also hide the other fields.
    // And if we can avoid adding one more option for this very rare configuration case, then let's avoid it.
    //
    // 3.
    // Fields for which we have hardcoded the mandatory prop (notably name for companies, lastName for individuals)
    // must be removed from the DOM if the layout hides them, otherwise the mandatory check will still occur.
    // Should the configurator does not (most probably forgots to) implement the same fields with the technique described in 2.
    // then the server will catch the mandatory error.

    return (
      <>
        {rows || personCard.props?.rows ? (
          <PersonGlobal {...props} />
        ) : [personTypes.INDIVIDUAL, personTypes.EMPLOYEE].includes(person.type) ? (
          <PersonIndividual {...props} />
        ) : [personTypes.COMPANY, personTypes.ORGANIZATION].includes(person.type) ? (
          <PersonCompany {...props} />
        ) : person.type === personTypes.PROFESSIONAL ? (
          <PersonProfessional {...props} />
        ) : null}
      </>
    )
  }
}

export default PersonComponent
